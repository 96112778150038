import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import "./Accordion-DrU-sWJL.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import { B as j } from "./Button-CAmI0_H8.js";
import { D as N } from "./DropdownMenu-CRQiwey-.js";
import { I as m } from "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as p } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import { c as y } from "./utils-CJ9afRe1.js";
import "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import { useState as w, useCallback as T } from "react";
import { T as k } from "./Toggle-D-drA526.js";
import "./Breadcrumbs.component-BrfVMNto.js";
import "./ClientFolder-Bmow2wjH.js";
import "./DocumentCard-BapFF9gL.js";
import { T as x } from "./UserTypeCard-CxIDlWQ3.js";
const C = ({
  children: n,
  className: u,
  headersLength: a = 10,
  allHeaders: d,
  selectedHeaders: i,
  fixedHeaders: o = [],
  toggleHeader: c,
  onReset: b
}) => {
  const [r, t] = w(!1), [l, f] = w(""), g = (s) => {
    i.length < a ? c(s) : f(
      `You can only add up to ${a} field${a > 1 ? "s" : ""} to Table View`
    );
  }, z = (s) => {
    c(s), f("");
  };
  return /* @__PURE__ */ e.jsxs(
    N,
    {
      modal: !1,
      open: r,
      onOpenChange: (s) => {
        t(s), f("");
      },
      children: [
        /* @__PURE__ */ e.jsx(N.Trigger, { className: y(u), asChild: !0, children: n ?? /* @__PURE__ */ e.jsxs(k, { size: "sm", pressed: r, children: [
          /* @__PURE__ */ e.jsx(m, { name: "GridAdd", width: 16, strokeWidth: 1.5 }),
          /* @__PURE__ */ e.jsx(p, { size: "sm", className: "ml-1", children: "Customize Table" })
        ] }) }),
        /* @__PURE__ */ e.jsxs(
          N.Content,
          {
            withPortal: !0,
            className: "border border-gray-neutral-20 rounded-md w-fit sm:w-[629px]",
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "py-2 px-6 pr-3 flex justify-between items-center border-b border-gray-neutral-80", children: [
                /* @__PURE__ */ e.jsx(p, { size: "sm", children: "Customize Table Columns" }),
                /* @__PURE__ */ e.jsx(
                  j,
                  {
                    variant: "secondary",
                    className: "rounded-full p-1 h-auto bg-blue-20",
                    onClick: () => t(!1),
                    children: /* @__PURE__ */ e.jsx(m, { name: "Cancel", width: 24, strokeWidth: 1.5 })
                  }
                )
              ] }),
              /* @__PURE__ */ e.jsxs("div", { className: "px-6 py-5 max-h-[550px] overflow-auto", children: [
                !!l && /* @__PURE__ */ e.jsxs(
                  p,
                  {
                    variant: "destructive",
                    size: "sm",
                    className: "mb-4 flex items-center",
                    children: [
                      /* @__PURE__ */ e.jsx(m, { name: "InfoEmpty", width: 20, className: "mr-2" }),
                      l
                    ]
                  }
                ),
                /* @__PURE__ */ e.jsxs("div", { className: "flex justify-between", children: [
                  /* @__PURE__ */ e.jsxs("div", { className: "rounded-md border border-gray-neutral-80 p-3 w-[45%]", children: [
                    /* @__PURE__ */ e.jsxs("div", { className: "flex items-center border-b border-gray-neutral-80 py-2", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "InfoEmpty",
                          width: 20,
                          strokeWidth: 1.5,
                          className: "text-blue-100"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(p, { size: "sm", className: "ml-1", children: "Info View" })
                    ] }),
                    /* @__PURE__ */ e.jsx("ul", { "data-testid": "info-view", children: d.filter(
                      (s) => !i.find(
                        (h) => h.id === s.id
                      )
                    ).map((s) => /* @__PURE__ */ e.jsxs("li", { className: "mt-4 flex items-center", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "GridAdd",
                          width: 24,
                          strokeWidth: 1.5,
                          className: "p-1 bg-black-10 rounded-full"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        p,
                        {
                          size: "sm",
                          className: "basis-full ml-3 capitalize truncate",
                          children: s.label
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        j,
                        {
                          "aria-label": "add",
                          className: "underline-none p-0 h-fit rounded-full",
                          onClick: () => g(s),
                          children: /* @__PURE__ */ e.jsx(m, { name: "Plus", width: 16, strokeWidth: 1.5 })
                        }
                      )
                    ] }, s.id)) })
                  ] }),
                  /* @__PURE__ */ e.jsxs("div", { className: "rounded-md border border-gray-neutral-80 p-3 w-[45%]", children: [
                    /* @__PURE__ */ e.jsxs("div", { className: "flex items-center border-b border-gray-neutral-80 py-2", children: [
                      /* @__PURE__ */ e.jsx(m, { name: "Table2Columns", width: 20, strokeWidth: 1.5 }),
                      /* @__PURE__ */ e.jsx(p, { size: "sm", className: "ml-1", children: "Table View" })
                    ] }),
                    /* @__PURE__ */ e.jsx("ul", { "data-testid": "table-view", children: i.map((s) => /* @__PURE__ */ e.jsxs("li", { className: "mt-4 flex items-center", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "GridAdd",
                          width: 24,
                          strokeWidth: 1.5,
                          className: "p-1 bg-black-10 rounded-full"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        p,
                        {
                          size: "sm",
                          className: "basis-full ml-3 capitalize truncate",
                          children: s.label
                        }
                      ),
                      o.find(
                        (h) => h.id === s.id
                      ) ? null : /* @__PURE__ */ e.jsx(
                        j,
                        {
                          "aria-label": "remove",
                          variant: "link",
                          className: "underline-none p-0 h-0",
                          onClick: () => z(s),
                          children: /* @__PURE__ */ e.jsx(
                            m,
                            {
                              name: "DeleteCircle",
                              width: 16,
                              strokeWidth: 1.5,
                              className: "text-gray-secondary"
                            }
                          )
                        }
                      )
                    ] }, s.id)) })
                  ] })
                ] }),
                b && /* @__PURE__ */ e.jsx(
                  j,
                  {
                    className: "p-2 mt-4 float-right",
                    "aria-label": "reset",
                    size: "sm",
                    onClick: b,
                    children: "Reset"
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
};
C.displayName = "CustomizedTableTrigger";
const v = ({
  children: n,
  className: u,
  data: a,
  headers: d
}) => /* @__PURE__ */ e.jsxs(x, { className: y(u), children: [
  /* @__PURE__ */ e.jsx(x.Header, { children: /* @__PURE__ */ e.jsx(x.Row, { children: d.map((i) => /* @__PURE__ */ e.jsx(x.Head, { className: "capitalize", children: i.label }, i.id)) }) }),
  n,
  !n && a && /* @__PURE__ */ e.jsx(x.Body, { children: a.map((i, o) => /* @__PURE__ */ e.jsx(x.Row, { children: d.map((c) => /* @__PURE__ */ e.jsx(x.Cell, { children: i[c.id] }, `${c}-${o}`)) }, o)) })
] }), U = Object.assign(v, { Trigger: C }), X = ({
  data: n,
  columns: u,
  headersLength: a = 10
}) => {
  let d = [];
  n && (d = n.length > 0 ? Object.keys(n[0]).map((r) => ({ id: r, label: r })) : []), u && (d = u);
  const [i, o] = w(d.slice(0, a)), c = (r) => {
    let t = [...i];
    return i.find((l) => (l == null ? void 0 : l.id) && l.id === r.id) ? t = i.filter((l) => (l == null ? void 0 : l.id) && l.id !== r.id) : t = [...i, r], o(t), t;
  }, b = T(
    (r) => {
      const t = r.slice(0, a);
      return o(t), t;
    },
    [o, a]
  );
  return {
    selectedHeaders: i,
    setSelectedHeaders: b,
    allHeaders: d,
    toggleHeader: c
  };
};
export {
  U as C,
  X as u
};
